import React, {Component} from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Index4 from './Pages/Index4';
import Aboutus1 from './Pages/Aboutus/Aboutus1';
import Expertise from './Pages/Expertise';
import OurProjects from './Pages/OurProjects';
import ProjectDetails from './Pages/ProjectDetails';
import Bellfield from './Pages/Projects/Bellfield';
import Playford from './Pages/Projects/Playford';

import ProjectManagement from './Pages/ProjectManagement';
import Team from './Pages/Team';
import Faqs from './Pages/Faqs';
import Contact from './Pages/Contact';
import Service from './Pages/Service/Service';
import ServicesDetails from './Pages/Service/ServicesDetails';
import BlogListRightSidebar from './Pages/BlogList/BlogListRightSidebar';
import BlogDetails from './Pages/BlogDetail/BlogDetails';
import ProtfolioFullWidth from './Pages/Protfolio/ProtfolioFullWidth';




import ScrollToTop from './Element/ScrollToTop';
import Blacktown from './Pages/Projects/Blacktown';
import Victoria from './Pages/Projects/Victoria';
import Perth from './Pages/Projects/Perth';
import JobOpportunities from './Pages/JobOpprtunities';
import Statistics from './Pages/Statistics';
import DonationPortal from './Pages/DonationPortal';
import Fundraising from './Events/Fundraising';

class Markup extends Component{
	render(){
		return(
			<BrowserRouter basename="/">
                <div className="page-wraper">
                    <Switch>
                        <Route path='/' exact component={Index4} />                        
                        <Route path='/about' exact component={Aboutus1} />                        
                        <Route path='/statistics' exact component={Statistics} />                        
                        <Route path='/donation-portal' exact component={DonationPortal} />                        
                        <Route path='/expertise' exact component={Expertise} />
                        <Route path='/projects' exact component={OurProjects} />
                        <Route path='/nsw' exact component={ProjectDetails} />
                        <Route path='/bellfield' exact component={Bellfield} />
                        <Route path='/playford' exact component={Playford} />
                        <Route path='/bellfield-rooty-hill' exact component={Blacktown} />
                        <Route path='/victoria' exact component={Victoria} />
                        <Route path='/perth' exact component={Perth} />
                        <Route path='/our-people' exact component={Team} />
                        <Route path='/faqs' exact component={Faqs} />
                        <Route path='/contact' exact component={Contact} />
                        <Route path='/service' exact component={Service} />
                        <Route path='/services-details' exact component={ServicesDetails} />
                        <Route path='/blog' exact component={BlogListRightSidebar} />
                        <Route path='/blog-details' exact component={BlogDetails} />
                        <Route path='/portfolio' exact component={ProtfolioFullWidth} />
                        <Route path='/our-history' exact component={ProjectManagement} />						
                        <Route path='/job-opportunities' exact component={JobOpportunities} />	
                        <Route path="/events/fundraising-dinner-04-feb-2024" exact component={Fundraising} />
					</Switch>
                </div>
				<ScrollToTop />
            </BrowserRouter>	
		)
	}
	
}

export default Markup;