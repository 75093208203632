import React, {Component,useState,useEffect,Fragment } from 'react';
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import Header from './../Layout/Header6';
import Footer from './../Layout/Footer1';
import SimpleReactLightbox from 'simple-react-lightbox';
import {SRLWrapper, useLightbox} from 'simple-react-lightbox'; 
import PageTitle from './../Layout/PageTitle';
import Masonry from 'react-masonry-component';
import bnr2 from './../../images/banner/bnr2.jpg';

//images
import box1 from './../../images/project/pic1.jpg';
import box2 from './../../images/project/pic2.jpg';
import box3 from './../../images/project/pic3.jpg';
import box4 from './../../images/project/pic4.jpg';
import box5 from './../../images/project/pic5.jpg';

const imageBlog = [
	{  Large: box1 , tag: ['completed',] , readmore:"bellfield", site:"https://www.bellfield.nsw.edu.au/", title: "Bellfield College" , description: "Bellfield College is an Islamic independent co-educational school, educating young Australians in a nurturing and affirming community." },
	{  Large: box2 , tag: ['completed'] , readmore:"playford", site:"https://playfordcollege.sa.edu.au/", title: "Playford College" , description: "We would be delighted to meet you should you be interested in visiting Playford College as you consider schooling options for your family." }, 
	{  Large: box3 , tag: ['upcoming', ] , readmore:"victoria", site:"#", title: "VICTORIA (UPCOMING PROJECT)" , description: "Upcoming project in Vitoria, Australia" }, 
	{  Large: box4 , tag: ['upcoming', ], readmore:"perth", site:"#", title: "PERTH (UPCOMING PROJECT)" , description: "Upcoming project in Perth, Australia" },
	{  Large: box5 , tag: ['upcoming', ], readmore:"bellfield-rooty-hill", site:"#", title: "Bellfield Rooty Hill" , description: "Upcoming project in Rooty Hill, NSW, Australia" }, 
	
]

//Light Gallery on icon click 
const Iconimage = props => {
	const { openLightbox } = useLightbox()

	return (
    <Link  to={"#"} onClick={() => openLightbox(props.imageToOpen)} className="mfp-link" >
      <i className="ti-fullscreen icon-bx-xs"></i>
    </Link>
  )
}

// Masonry section
const masonryOptions = {                                          
    transitionDuration: 0
};

const imagesLoadedOptions = { background: '.my-bg-image-el' }
// Masonry section end

const TagLi = ({name, handlesettag, tagActive}) => {                                                               
	return ( 
			
		<li className={` tag ${tagActive ? 'btn active' : 'btn'}` } onClick={() => handlesettag(name)} >
			<input type="radio" />
			<Link to={"#"} className="site-button-secondry" ><span>{name} {''}</span> </Link>
		</li> 
	);
};


class Portfolio2 extends Component{
	render(){
		return(
			<Fragment>	
			<Header />
			<div className="page-content bg-white">
				{/*  banner  */}
				<div className="dlab-bnr-inr overlay-primary" style={{ backgroundImage: "url(" + bnr2 + ")" }}>
					<PageTitle motherMenu="Our Projects"  activeMenu="Our Projects"  />
				</div>
				{/*  Section-1 Start  */}
				<PortfolioItem />
				
			</div>	
			<Footer />
			</Fragment>			
		)
	}
} 
function PortfolioItem(){
	const [tag, setTag] = useState('Show All');
	const [filteredImages, setFilterdImages] = useState([]);
	
	useEffect( () => {
		tag === 'Show All' ? setFilterdImages(imageBlog) : setFilterdImages(imageBlog.filter( image => image.tag.find(element => element === tag)))
	}, [tag])	
	
	return(
		<>
			<div className="section-full content-inner portfolio-section mfp-gallery">		
				<div className="container-fluid">
					<div className="section-head text-black text-center">
						<p>Here you’ll find a list of schools and projects Illuminate Australia have been involved in establishing.</p>
						<p>We have upcoming projects coming up in Blacktown (NSW), Victoria and Perth. Stay Tuned!</p>
					</div>	
					<div className="site-filters clearfix center  m-b40">
						<ul className="filters" data-toggle="buttons">
							<TagLi name="Show All"  handlesettag={setTag} tagActive={ tag === 'Show All' ? true : false }	/>
							<TagLi name="completed"  handlesettag={setTag} tagActive={ tag === 'completed' ? true : false }	/>
							<TagLi name="upcoming" handlesettag={setTag} tagActive={ tag === 'upcoming' ? true : false }	 />
						</ul>
					</div>
					<SimpleReactLightbox>
							<SRLWrapper >
							<div className="">
								<ul id="masonry" className="dlab-gallery-listing gallery-grid-4 mfp-gallery ">
									<Masonry
										className={'my-gallery-class'} // default ''
										options={masonryOptions} // default {}
										disableImagesLoaded={false} // default false
										updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
										imagesLoadedOptions={imagesLoadedOptions} // default {}
									>
										{filteredImages.map((item, index)=>(
											<li className="card-container col-xl-4 col-lg-4 col-md-6 col-sm-6 m-b30 drupal php wordpress" key={index}>
												<div className="dlab-box portfolio-box">
													<div className="dlab-media dlab-img-effect dlab-img-overlay1"> <img src={item.Large} alt={item.title} />
														<div className="dlab-info-has p-a15 bg-primary">
															<Link to={item.readmore} className="site-button outline radius-xl white">{item.tag}</Link>
															<a href={item.site} target='_blank' rel='noopener noreferrer' className="site-button outline radius-xl white pull-right">Vist Site</a>
														</div>
														<div className="overlay-bx">
															<div className="overlay-icon text-white p-lr10">
																<h5>{item.title}</h5>
																<p className="m-b10">{item.description}</p>
																<Iconimage />
																<a href={item.readmore} target="_blank" rel="noopener noreferrer"><i className="ti-arrow-top-right icon-bx-xs"></i></a>
															</div>
																
														</div>
													</div>
												</div>
											</li>
										))}	
									</Masonry>	
								</ul>
							</div>
						</SRLWrapper>
					</SimpleReactLightbox>	
				</div>	
			</div>
		</>
	);
}

function ProjectSidebar(){
	var settings = {		
		arrows: false,
		slidesToShow: 1,		
		infinite: true,
		dots: true,
		responsive: [
			{
			  breakpoint: 1200,
			  settings: {
				slidesToShow: 1,
			  }
			},
			{
			  breakpoint: 1024,
			  settings: {
				slidesToShow: 1,
			  }
			},
			{
			  breakpoint: 480,
			  settings: {
				slidesToShow:1,
			  }
			}
		]
	};
		return(			
			<>
				<div className="widget widget-project">
					<h5 className="widget-title style-1">Our Project</h5>
					<Slider className="testimonial-box center-active-owl item-center owl-loaded owl-carousel owl-none  owl-dots-black-full " {...settings}>
					{imageBlog.map((item, index)=>(
						<div className="item" key={index}>
							<img src={item.Large} alt={item.title} />
						</div>
					))}
					</Slider>
				</div>
			</>
		)
	
}
export {ProjectSidebar};
export default Portfolio2;
