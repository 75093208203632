import React,{Component} from 'react';
import {Link} from 'react-router-dom';

class HeaderMenu1 extends Component{
	render(){
		return(
			<>
				<ul className="nav navbar-nav">
					<li className="active"><Link to={'/'}><span className="ti-home"></span> </Link></li>
					<li><Link to={'/'}>Home  <i className="fa fa-chevron-down"></i></Link>
					<ul className="sub-menu">
						<li><Link to={'/'} className="dez-page">General Description</Link></li>
						<li><Link to={'/statistics'} className="dez-page">Statistics</Link></li>
					</ul>
					</li>
					<li><Link to={'/about'}>Mission and Vision</Link></li>
					<li><Link to={'/our-people'}>Our People</Link></li>
					<li><Link to={'/donation-portal'}>Donation</Link></li>
					<li><Link to={'/our-history'}>Our History</Link></li>
					<li><Link to={'/projects'}>Projects <i className="fa fa-chevron-down"></i></Link>
						<ul className="sub-menu">
							<li><Link to={'#'}>NSW <i className="fa fa-angle-right"></i></Link>
								<ul className="sub-menu">
									<li><Link to={'/bellfield'} className="dez-page">Bellfield College</Link></li>
									<li><Link to={'/bellfield-rooty-hill'} className="dez-page">Bellfield Rooty Hill</Link></li>
								</ul>
							</li>
							<li><Link to={'#'}>VIC <i className="fa fa-angle-right"></i></Link>
								<ul className="sub-menu">
									<li><Link to={'/victoria'} className="dez-page">Victoria (Upcoming)</Link></li>
								</ul>
							</li>
							<li><Link to={'#'}>SA <i className="fa fa-angle-right"></i></Link>
								<ul className="sub-menu">
									<li><Link to={'/playford'} className="dez-page">Playford College</Link></li>
								</ul>
							</li>
							<li><Link to={'#'}>WA <i className="fa fa-angle-right"></i></Link>
								<ul className="sub-menu">
									<li><Link to={'/perth'} className="dez-page">Perth (Upcoming)</Link></li>
								</ul>
							</li>
						</ul>
					</li>
                    <li><Link to={'./job-opportunities'}>Job Opportunities</Link></li>                    
					<li><Link to={'./portfolio'}>Portfolio</Link></li>					
				</ul>
			</>
		)
	}
}
export default HeaderMenu1;