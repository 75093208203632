import React, { Component } from 'react';
import Header from './../../Layout/Header6';
import Footer from './../../Layout/Footer1';
import PageTitle from './../../Layout/PageTitle';

//Images 
import bnr1 from './../../../images/banner/bnr2.jpg';
import ProjectCounter from '../../Element/ProjectCounter';
import ProjectCarousel from './../../Element/ProjectCarousel'
import pic1 from './../../../images/gal/bellfield/3.jpg';
import pic2 from './../../../images/gal/bellfield/4.jpg';
import pic3 from './../../../images/gal/bellfield/5.jpg';

const carouselImg =[
	{pic: pic1},
	{pic: pic2},
	{pic: pic3},
]

class Bellfield extends Component {
    /* componentDidMount(){
        window.abc();
    } */
    render() {
        return (
            <>
                <Header />
                <div className="page-content bg-white">
                    <div className="dlab-bnr-inr overlay-primary" style={{ backgroundImage: "url(" + bnr1 + ")" }}>
                        <PageTitle motherMenu='Bellfield COllege' activeMenu='Bellfield COllege' />  
                    </div>
                    <div className="content-block">
                        <div className="section-full content-inner-2">
                            <div className="container">
                                <div className="row m-b40">
                                    <div className="col-lg-6 col-md-6 m-b20">
                                        <h2 className="m-b10 m-t0">Project : Bellfield College</h2>
                                        <p className="m-b10">Bellfield College is an Islamic independent co-educational school, educating young Australians in a nurturing and affirming community.</p>
                                        <p className="m-b10">We aim to play a vital role in providing for the overall development of every student through our commitment toward supporting every family in their endeavours to strive in the way of Allah (swt). This will be achieved by facilitating Parents in being more effective educators of their children through courses, workshops, seminars, and discussion groups designed specifically for them.</p>
                                        <a href="#" target='_blank' rel="noopener noreffer" className="site-button radius-xl outline">Visit Website <i className="fa fa-long-arrow-right"></i></a>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <ProjectCarousel imglist={carouselImg} />
                                    </div>
                                </div>
                                <div className="row m-b50">
                                    <div className="col-md-6 m-b20">
                                        <div className="p-a30 bg-gray">
                                            <h6>Project Name: <span className="m-l10 font-weight-300 text-gray-dark"> Bellfield College</span></h6>
                                            <h6>Project Commencement Date: <span className="m-l10 font-weight-300 text-gray-dark"> 01 July, 2008</span></h6>
                                            <h6>Project Completion Date: <span className="m-l10 font-weight-300 text-gray-dark"> 30 June, 2009</span></h6>
                                            <h6>Project Url: <span className="m-l10 font-weight-300 text-gray-dark">www.bellfield.nsw.edu.au</span></h6>
                                            <h6>Email: <span className="m-l10 font-weight-300 text-gray-dark">admin@bellfield.nsw.edu.au</span></h6>
                                            <h6>Phone: <span className="m-l10 font-weight-300 text-gray-dark">(02) 9606 2666</span></h6>
                                        </div>
                                    </div>
                                    <div className="col-md-6 align-self-center">
                                        <h5 className="text-black fw5 m-b10">An educational initiative to build faith-based schools to teach wisdom through faith and knowledge.</h5>
                                        <p className="m-b0">We want to raise a generation of morally upright students who can contribute to the wider Australian society with the two wings of faith (Iman) and knowledge (I’lm). Our mission is to establish Independent Not for Profit faith-based Schools aligned with Islamic values in all major cities of Australia.</p>
                                    </div>
                                </div>
                            </div>
                            <ProjectCounter/>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        )
    }
}
export default Bellfield;