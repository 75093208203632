import React, { Component } from 'react';
import Header from './../Layout/Header6';
import Footer from './../Layout/Footer1';
import PageTitle from './../Layout/PageTitle';

//Images
import rocket from './../../images/logo/logo.png';
import logo from './../../images/logo/logo.png';
import bnr from './../../images/banner/bnr7.jpg';

 
const BlogLeft = ({ title, number, description }) => {
   return (
		<>
			<div className="line-left clearfix">
                <div className="line-left-box">
					<div className="line-content-box ">
						<h4 className="min-title text-uppercase text-primary m-t0 m-b15">{title}</h4>
						<p className="font-16">
							{description}
						</p>
					</div>
					<div className="line-num bg-primary-dark">{number}</div>
				</div>
			</div>	
		</>
    );
};
const BlogRight = ({ title, number , description }) => {
   return (
		<>
			<div className="line-right clearfix">
                <div className="line-right-box">
					<div className="line-content-box ">
						<h4 className="min-title text-uppercase text-primary m-t0 m-b15">{title}</h4>
						<p className="font-16">
							{description}
						</p>
					</div>
					<div className="line-num bg-primary-dark">{number}</div>
				</div>
			</div>	
		</>
    );
};

class ProjectManagement extends Component {
    render() {
        return (
            <>
                <Header />

                <div className="page-content bg-white">
                    <div className="dlab-bnr-inr overlay-primary" style={{ backgroundImage: "url(" + bnr + ")" }}>
                        <PageTitle motherMenu='Our History' activeMenu='Our History' />
                    </div>
                    <div className="content-block">
                        <div className="section-full content-inner-1">
                            <div className="container">
                                <div className="section-head text-black text-center">
                                    <h2 className="box-title m-tb0">How our Journey Starts</h2>
                                    <p>The below timeline shows how we grow in last 18 years of comprehensive Journey.</p>
                                </div>
                            </div>
                            <div className="container m-b50">
                                <div className="row">
                                    <div className="col-lg-12 text-center">
                                        <img src={rocket} alt="" className="fa faa-horizontal animated" />
                                        <h5 className="text-primary">Start Project</h5>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="time-line clearfix">
                                            <BlogLeft title='2005' number='1' description="A growing need for Shia Community to have faith based school." />  
											<BlogRight title='2008' number='2' description="Bellfield College was established." />
											<BlogLeft title='2010' number='3' description="Due to demand in other states Illuminate Australia was born under the leadeship of Haj Ali Hossein." />
											<BlogRight title='2013' number='4' description="Adelaide's college started in collaboration with Illuminate Australia." />
											<BlogLeft title='2016' number='5' description="With efforts of Adelaide's community and support of Illuminate Australia, Playford college was established" />
											<BlogRight title='2017' number='6' description="Working on other school projects nation-wide. Developing the structure and services provided by Illuminate Australia." />
                                            <BlogLeft title='2023' number='7' description="Illuminate VIC and WA were established and registered." />
                                            <BlogRight title='2024' number='8' description="1.Rooty Hill NSW construction started. 2.Moorroopna College VIC approved as a school site" />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 text-center">
                                        <h5 className="text-primary">More to follow</h5>
                                        <img src={logo} alt="" className="fa faa-horizontal animated" />
                                    </div>
                                </div>
                            </div>
                        </div>                        
                    </div>
                </div>

                <Footer />
            </>
        )
    }
}
export default ProjectManagement;